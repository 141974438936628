<template>
    <div class="page">
        <app-header :navIndex="2"></app-header>
        <banner :data="banner"></banner>
        <section class="top-box">
            <div class="top-border hidden-md-and-up white"></div>
            <div class="desc section-container mt-md-12 mb-md-10 pa-5">{{banner.desc}}</div>
            <div class="d-flex flex-column">
                <div class="d-flex flex-column flex-md-row align-center" v-for="(item,index) in list" :key="index">
                    <div class="col-md-6 col-sm-12 order-md-0 pa-0">
                        <v-img :src="item.img"></v-img>
                    </div>
                    <div class="col-md-6 col-sm-12"
                         :class="index%2==0?'pl-md-12 pr-md-12 order-md-1':'pr-md-12 pl-md-12 order-md-first'">
                        <div class="desc" :class="index%2==0?'pr-md-12 mr-md-8':'pl-md-12 ml-md-8'">{{item.desc}}</div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import Banner from "../../components/base/banner";
    import AppHeader from "../../components/core/header/index";
    export default {
        components: {
            AppHeader,
            Banner
        },
        data(){
            return {
                banner: {
                    imgUrl: 'product/gcc/bg.jpg',
                    title: 'Fine wines',
                    enTitle: 'GRAND CRU CLASSE',
                    desc: 'ASC is a professional and well-capitalized fine wine specialist and has been dealing with fine wines in China for over 24 years.'
                },
                list: [{
                    img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/product/gcc/img_one.jpg'),
                    desc: 'In the past 26 years, ASC Fine Wines has successfully cooperated with over 120 wineries/producers, importing over RMB 130 million fine wines annually, covering 1300 SKUs and 20 appellations. ASC’s in-house wine experts are always on standby to advise you on fine wine purchases, whether for business or for pleasure. Moreover, ASC Fine Wines is a pioneer in offering En Primeur in China for over 17 years, we have long-term, well-established relationship with Bordeaux Chateaux and negociants, ensuring sufficient wine supply and rich selection.'
                }, {
                    img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/product/gcc/img_two.jpg'),
                    desc: 'ASC Fine Wines launched its auction business in 2009 and became the first wine company officially authorized to run auction business in China in 2010.In 2014, ASC signed up with China Guardian, the leading auction house in Mainland China to offer annual spring and autumn sales and quarterly wine auctions.'
                }]
            }
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "精品葡萄酒服务"
            document.getElementById("bodyId").appendChild(div);
        },
    }
</script>
<style lang="scss" scoped>

</style>
